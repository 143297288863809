import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {WidgetPluginInterfaces} from '@wix/widget-plugins-interfaces';
import {StoresWidgetID} from '@wix/wixstores-client-core';

export const configureSlots = (appManifestBuilder: AppManifestBuilder): AppManifestBuilder =>
  appManifestBuilder
    .configureWidget(StoresWidgetID.CHECKOUT, (widgetBuilder) => {
      widgetBuilder.slots().set({
        'above-summary-checkout-slot': {
          displayName: 'Above The Summary Checkout Slot',
          interfaces: [WidgetPluginInterfaces.DEFAULT],
        },
        'summary-checkout-slot': {
          displayName: 'Summary Checkout Slot',
          interfaces: [WidgetPluginInterfaces.DEFAULT],
        },
        'below-summary-checkout-slot': {
          displayName: 'Below The Summary Checkout Slot',
          interfaces: [WidgetPluginInterfaces.DEFAULT],
        },
      });
    })
    .configureWidget(StoresWidgetID.GRID_GALLERY, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.SLIDER_GALLERY, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.WISHLIST_PAGE, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.GALLERY_PAGE, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.CATEGORY_PAGE, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    });

const galleryWidgetBuilder = (widgetBuilder) => {
  widgetBuilder.slots().set({
    'gallery-rating-summary-slot': {
      displayName: 'Rating Summary Slot',
      interfaces: [WidgetPluginInterfaces.RATINGS_SUMMARY_OOI_LIST],
    },
  });
};
