import {Events, SPECS} from '../constants';
import {DependantApps} from '../services/DependantApps';
import {doTransaction} from '../transaction';
import {handleAppsManagerActions} from '../../common/appManifest/appManifest';
import {EditorSDK} from '@wix/platform-editor-sdk';
import {ExperimentsApi} from '../../common/experiments/ExperimentsApi';
import {errorReporter} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';

export async function handleOnEvent(
  {eventType, eventPayload}: {eventType: Events; eventPayload: any},
  sdk: EditorSDK,
  dependantApps: DependantApps,
  experimentsApi: ExperimentsApi
) {
  switch (eventType) {
    case Events.manageStores:
      return sdk.editor.openDashboardPanel('', {url: 'store/products', closeOtherPanels: false});
    case Events.dashboardClosed:
      return doTransaction(sdk, () => {
        if (experimentsApi.enabled(SPECS.RefreshRoutersAfterDashboardClosed)) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          void sdk.editor.routers.refresh('').catch(errorReporter);
        }
        return dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded();
      });
    case Events.appActionClicked:
      return handleAppsManagerActions(sdk, eventPayload.actionId);
  }
}
