import {EditorSDK} from '@wix/editor-platform-sdk-types';
import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {LocaleKeys} from '../../__generated__/LocaleKeys';
import {StoresWidgetID} from '@wix/wixstores-client-core';

const baseSettingsPanelOptions: Partial<Parameters<EditorSDK['editor']['openComponentPanel']>[1]> = {
  width: 404,
  height: 583,
  type: 'settings',
  helpId: '013e41af-c3cd-4071-88a8-293258584978',
};

const openManageProducts = (editorSDK: EditorSDK) => {
  return editorSDK.editor.openDashboardPanel('', {
    url: 'store/products',
    closeOtherPanels: true,
  });
};

const bindConfiguratorToWidgetId =
  ({widgetId, settingsTitle}: {widgetId: string; settingsTitle: string}) =>
  ({t, editorSDK}: {t: (key: string) => string; editorSDK: EditorSDK}) =>
  (appManifestBuilder: AppManifestBuilder): AppManifestBuilder =>
    appManifestBuilder.configureWidget(widgetId, (widgetBuilder) =>
      widgetBuilder
        .gfpp()
        .set('help', {
          id: '4bf6919c-d2a4-45d4-99cb-a0928bd7e08d',
        })
        .set('settings', {
          onClick: async (event) =>
            editorSDK.editor.openComponentPanel('', {
              ...baseSettingsPanelOptions,
              title: t(settingsTitle),
              url: 'https://ecom.wix.com/storefront/settings-gridgallery',
              componentRef: event.detail.componentRef,
            }),
        })
        .set('mainAction1', {
          label: t(LocaleKeys().settings.shopPage.gfpp.mainAction.manageProducts),
          onClick: () => openManageProducts(editorSDK),
        })
        .set('mainAction2', {
          label: t(LocaleKeys().settings.shopPage.gfpp.mainAction.chooseCollection),
          onClick: (event) =>
            editorSDK.editor.openComponentPanel('', {
              ...baseSettingsPanelOptions,
              title: t(settingsTitle),
              url: 'https://ecom.wix.com/storefront/settings-gridgallery/collection',
              componentRef: event.detail.componentRef,
            }),
        })
    );

const bindCategoryPageConfigurator =
  () =>
  ({t, editorSDK}: {t: (key: string) => string; editorSDK: EditorSDK}) =>
  (appManifestBuilder: AppManifestBuilder): AppManifestBuilder =>
    appManifestBuilder.configureWidget(StoresWidgetID.CATEGORY_PAGE, (widgetBuilder) =>
      widgetBuilder
        .gfpp()
        .set('help', {
          id: '7e3bde99-2952-4acf-a80d-45c80143f32a',
        })
        .set('mainAction1', {
          label: t(LocaleKeys().settings.shopPage.gfpp.mainAction.manageProducts),
          onClick: () => openManageProducts(editorSDK),
        })
    );

export const configureCategoryGfpp = bindCategoryPageConfigurator();

export const configureStorePageGfpp = bindConfiguratorToWidgetId({
  widgetId: StoresWidgetID.GALLERY_PAGE,
  settingsTitle: LocaleKeys().settings.shopPage.common.panelHeader,
});

export const configureGridGalleryGfpp = bindConfiguratorToWidgetId({
  widgetId: StoresWidgetID.GRID_GALLERY,
  settingsTitle: LocaleKeys().settings.gridGallery.common.panelHeader,
});
